import React, {useEffect} from 'react';
import EnrollmentForm from './EnrollmentForm';
import QueryForm from "./QueryForm";
import FAQ from "./FAQ";
import Footer from "./footer";
import Down from "./Down";
import ReactPixel from "react-facebook-pixel";

function App() {
    useEffect(() => {
        ReactPixel.init('1209668043271755');
        ReactPixel.pageView();
    }, []);
    return (
        <div>
            // <EnrollmentForm/>
            // <QueryForm/>
            // <FAQ/>
            // <Footer/>
            <Down/> 
        </div>
);
}

export default App;
